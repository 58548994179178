import React, { useContext, useEffect, useState } from "react"
import AuthContext from '../../context/AuthProvider';
import { validateEmail } from '../../helpers/general'
import { bcApi } from '../../helpers/bigcommerce'
import { update } from '../../helpers/forcite'
import { success } from '../../helpers/toast';

import FormInputField from "../../components/atoms/FormInputField/FormInputField";
import AccountPageWrapper from "../../components/organisms/AccountPageWrapper/AccountPageWrapper"
import Button from "../../components/atoms/Button/Button"

/* Enable for Klavyio integration */
// import { getListsByEmail, subscribe, unsubscribe } from '../../helpers/klaviyo';
// import Loader from '../../components/atoms/Loader/Loader';
// import Dialog from "../../components/atoms/Dialog/Dialog"

import * as styles from './account.module.css';
import ContentfulContext from "../../context/ContentfulProvider";
import { get } from "lodash";
import ContentfulTranslationText from "../../components/atoms/ContentfulTranslationText/ContentfulTranslationText";

const Account = ({ transObj }) => {
    const translations = transObj;

    const auth = useContext(AuthContext);
    const checkPassword = auth && auth.checkPassword;
    const requiredFields = {
        'first_name': 'Name',
        'last_name': 'Surname',
        'email': 'Email'
    }

    const translateKeys = {
        'first_name': 'firstName',
        'last_name': 'lastName',
        'email': 'emailAddress'
    }

    const defaultFields = {
        first_name: '',
        last_name: '',
        phone: '',
        company: '',
        email: '',
        current_password: '',
        new_password: '',
        confirm_password: '',
        authentication: {new_password: ''},
    }

    const [accountDetails, setAccountDetails] = useState(defaultFields);
    const [fieldErrors, setFieldErrors] = useState({});
    const [editing, toggleEditing] = useState(false);
    const [defaultSet, toggleDefaultSet] = useState(false);
    const [busy, setBusy] = useState(false);

    /* Enable if you have custom fields for a customer */
    // const [customDetails, setCustomDetails] = useState(customFields);

    /* Enable for Klavyio integration */
    // const [emailLists, setEmailLists] = useState(false);
    // const [fetchingLists, setFetchingLists] = useState(false);
    // const [subscribed, setSubscribed] = useState({});
    // const [dialogMessage, setDialogMessage] = useState(false);

    useEffect(() => {
        if (!defaultSet && (auth && 'state' in auth && 'object' in auth.state)) {
            setAccountDetails({
                ...accountDetails, 
                ...{
                    first_name: auth.state.object.first_name,
                    last_name: auth.state.object.last_name,
                    phone: auth.state.object.phone,
                    company: auth.state.object.company,
                    email: auth.state.object.email,
                }
            });

            /* Enable if you have custom fields for a customer */
            // const exampleCustomField = auth.state.object.form_fields?.filter(formField => formField.name === 'Custom Field');
            // setCustomDetails({
            //     ...customDetails,
            //     ...{
            //     'Custom Field': (exampleCustomField && exampleCustomField.length > 0) ? exampleCustomField[0].value : '',
            //     }
            // })

            toggleDefaultSet(true);
        }
    }, [auth, accountDetails, defaultSet, toggleDefaultSet]); // customDetails

    /* Enable for Klavyio integration */
    // useEffect(() => {
    //     if (!emailLists && !fetchingLists && accountDetails.email !== '') {
    //       setFetchingLists(true);
    //       getListsByEmail(accountDetails.email).then(emailLists => {
    //         setEmailLists(emailLists);
    //         const subscribedData = {};
    //         emailLists.map(list => {
    //           subscribedData[list.list.list_id] = list.subscribed.response.length > 0;
    //           return true;
    //         });
    //         setSubscribed(subscribedData);
    //         setFetchingLists(false);
    //       });
    //     }
    //   }, [accountDetails, emailLists, fetchingLists]);
    
    //   const toggleSubscription = (e, listId) => {
    //     const checked = e.target.checked;
    //     const parentElement = e.target.parentNode;
    //     parentElement.classList.add(styles.loading);
    //     const complete = new Promise((res) => {
    //       if (checked) {
    //         // subscribe to list
    //         subscribe(listId, accountDetails.email).then(response => {
    //           // console.log('Subscribed to ', listId);
    //           setDialogMessage(true);
    //           res(true);
    //         });
    //       } else {
    //         // unsubscribe from list
    //         unsubscribe(listId, accountDetails.email).then(response => {
    //           // console.log('Unsubscribed from ', listId);
    //           res(true);
    //         });
    //       }
    //     });
    
    //     complete.then(() => {
    //       const subscribedData = {...subscribed};
    //       subscribedData[listId] = checked;
    //       parentElement.classList.remove(styles.loading);
    //       setSubscribed(subscribedData);
    //     });
    //   }
    
    //   const clearDialog = () => {
    //     setDialogMessage(false);
    //   }
    /* END Klavyio integration code */

    const handleChange = (id, fieldValue) => {
        const newField = {};        
        newField[id] = fieldValue;
        setAccountDetails({...accountDetails, ...newField});
    }

    const saveDetails = async () => {
        const promises = [];
        let valid = true;
        const errors = {}
        setFieldErrors({})

        // Check if required fields are not blank
        Object.keys(requiredFields).map((field) => {
            if (accountDetails[field] === '') {
                valid = false;
                errors[field] = `${get(translations, translateKeys[field] + '.title', requiredFields[field])} ${get(translations, 'accountPageEmptyFieldError.title', 'can not be blank')}`
                // errors[field] = `${requiredFields[field]} ${get(translations, 'accountPageEmptyFieldError.title', 'can not be blank')}`
            }
            return true;
        })

        // Validate the email address
        if (!validateEmail(accountDetails.email)) {
            valid = false;
            errors.email = get(translations, 'emailInvalidError.title', 'This email address is invalid')
        }

        // Check if password is set to change and matches
        if (accountDetails.new_password !== '') {
            if (accountDetails.new_password !== accountDetails.confirm_password) {
                valid = false;
                errors.confirm_password = get(translations, 'passwordNotMatch.title', 'Passwords do not match')
            }

            if (accountDetails.current_password === '') {
                valid = false;
                errors.current_password = get(translations, 'requiredCurrentPasswordError.title', 'Current password is required to set new password')
            }

            if (accountDetails.current_password !== '') {
                promises.push(new Promise(res => {
                    checkPassword(auth.state.object.email, accountDetails.current_password).then(({response, forciteAuthResp}) => {
                        // console.log("Confirming existing password", response, forciteAuthResp);
                        if ('errors' in response || !('userId' in forciteAuthResp)) {
                            valid = false;
                            errors.current_password = get(translations, 'wrongCurrentPasswordError.title', 'Current password is not correct')
                        }
                        res(true)
                        return true;
                    }).catch(error => {
                        // console.log("Confirming existing password", error);
                        valid = false;
                        errors.current_password = get(translations, 'wrongCurrentPasswordError.title', 'Current password is not correct')
                        res(true)
                        return true;
                    });
                }))
            }
        }
        
        setBusy(!busy);
        Promise.all(promises).then(() => {
            const forciteUserId = auth.state.object.form_fields.find(field => field.name === 'Forcite User ID')?.value || false;
            if (valid && forciteUserId) {
                // Run process to save details
                const fields = accountDetails;
                const forciteFields = {
                    userId: forciteUserId,
                    fname: fields.first_name,
                    surname: fields.last_name,
                    email: fields.email,
                    companyName: fields.company,
                    phone: fields.phone
                };
                if (accountDetails.new_password !== '') {
                    forciteFields.password = accountDetails.new_password;
                }
                delete fields.authentication.new_password;

                // delete fields.current_password;
                // delete fields.new_password;
                // delete fields.confirm_password;
                fields.current_password = '';
                fields.new_password = '';
                fields.confirm_password = '';
                fields.id = auth.state.customerId;

                const updaters = [];
                
                // UPDATE BC DATA
                updaters.push(new Promise(res => {
                    bcApi('customers', 'PUT', [fields]).then(() => {
                        res(true);
                    });
                }));

                // UPDATE FORCITE DATA
                updaters.push(new Promise(res => {
                    update(forciteFields).then(() => {
                        res(true);
                    });
                }));

                // Wait until all updaters have completed;
                Promise.all(updaters).then(() => {
                    setAccountDetails({...defaultFields, ...fields});
                    success(get(translations, 'profileUpdated.title', 'Profile updated.'))
                    toggleEditing(!editing);
                    setBusy(!busy);
                })
            } else {
                setBusy(!busy);
                setFieldErrors(errors)
            }
        })
    }

    const itemClasses = editing
        ? `${styles.details} ${styles.edit}`
        : `${styles.details} ${styles.view}`;
    const displayClasses = editing ? `hidden ${styles.noWidth}` : `show`;
    const reverseDisplayClasses = !editing ? `hidden ${styles.noWidth}` : `show`;
    // const inputClasses = `formField mb-0 ${!editing ? `hidden ${styles.noWidth}` : 'show'}`;
    const labelClasses = editing ? 'hidden' : `show ${styles.label}`;
    const inputStyleChange = editing ? styles.inputStyleChange : styles.defaultStyle;

    return (
        <div>
        <div className={`${styles.container}`}>
            <div>
                <div className={`${itemClasses} ${inputStyleChange} ${displayClasses}`}>
                    <span>{get(translations, 'firstName.title', 'First Name')}<span className={styles.label}>:</span></span>
                    <div className={labelClasses}>{accountDetails.first_name}</div>
                </div>
                <div className={reverseDisplayClasses}>
                    <FormInputField 
                        id='first_name' 
                        labelName={get(translations, 'firstName.title', 'First Name')}
                        value={accountDetails.first_name} 
                        handleChange={handleChange} 
                        error={fieldErrors.first_name}
                    />
                    <div className={styles.errorContainer}>
                        <span className={styles.error}>
                            {fieldErrors.first_name}
                        </span>
                    </div>
                </div>
            </div>
            <div>
                <div className={`${itemClasses} ${inputStyleChange} ${displayClasses}`}>
                    <span>{get(translations, 'lastName.title', 'Last Name')}<span className={styles.label}>:</span></span>
                    <div className={labelClasses}>{accountDetails.last_name}</div>
                </div>
                <div className={reverseDisplayClasses}>
                    <FormInputField 
                        id='last_name' 
                        labelName={get(translations, 'lastName.title', 'Last Name')} 
                        value={accountDetails.last_name} 
                        handleChange={handleChange} 
                        error={fieldErrors.last_name}
                    />
                    <div className={styles.errorContainer}>
                        <span className={styles.error}>
                            {fieldErrors.last_name}
                        </span>
                    </div>
                </div>
            </div>
            <div>
                <div className={`${itemClasses} ${inputStyleChange} ${displayClasses}`}>
                        <span>{get(translations, 'emailAddress.title', 'Email Address')}<span className={styles.label}>:</span></span>
                        <div className={labelClasses}>{accountDetails.email}</div>
                    </div>
                    <div className={reverseDisplayClasses}>
                        <FormInputField 
                            id='email' 
                            labelName={get(translations, 'emailAddress.title', 'Email Address')} 
                            value={accountDetails.email} 
                            handleChange={handleChange} 
                            error={fieldErrors.email}
                            disabled={true}
                            note={get(translations, 'emailAddressCanNotUpdate.title', 'Email address can not be updated. Please contact us.')}
                        />
                        <div className={styles.errorContainer}>
                            <span className={styles.error}>
                                {fieldErrors.email}
                            </span>
                        </div>
                    </div>
            </div>
            <div>
                <div className={`${itemClasses} ${inputStyleChange} ${displayClasses}`}>
                    <span>{get(translations, 'phoneNumber.title', 'Phone Number')}<span className={styles.label}>:</span></span>
                    <div className={labelClasses}>{accountDetails.phone}</div>
                </div>
                <div className={reverseDisplayClasses}>
                    <FormInputField 
                        id='phone' 
                        labelName={get(translations, 'phoneNumber.title', 'Phone Number')} 
                        value={accountDetails.phone} 
                        handleChange={handleChange} 
                        error={fieldErrors.phone}
                    />
                    <div className={styles.errorContainer}>
                        <span className={styles.error}>
                            {fieldErrors.phone}
                        </span>
                    </div>
                </div>
            </div>
            <div>
                <div className={`${itemClasses} ${inputStyleChange} ${displayClasses}`}>
                    <span>{get(translations, 'companyName.title', 'Company Name')}<span className={styles.label}>:</span></span>
                    <div className={labelClasses}>{accountDetails.company}</div>
                </div>
                <div className={reverseDisplayClasses}>
                    <FormInputField 
                        id='company' 
                        labelName={get(translations, 'companyName.title', 'Company Name')} 
                        value={accountDetails.company} 
                        handleChange={handleChange} 
                        error={fieldErrors.company}
                    />
                    <div className={styles.errorContainer}>
                        <span className={styles.error}>
                            {fieldErrors.company}
                        </span>
                    </div>
                </div>
            </div>

            <div>
                <div className={`${itemClasses} ${styles.password} ${displayClasses} ${inputStyleChange}`}>
                        <span><ContentfulTranslationText keyName="password">Password</ContentfulTranslationText><span className={labelClasses}>:</span></span>
                    <div>
                        <div className={labelClasses}>********</div>
                    </div>
                </div>
            </div>
            {/* Enable if you have custom fields for a customer
            (customDetails['Custom Field'] || editing) && (
                <div className={`${itemClasses} ${inputStyleChange}`}>
                <span>Custom Field:</span>
                <div>
                    <div className={labelClasses}>{customDetails['Custom Field']}</div>
                    <div className={inputClasses}>
                    <input
                        type="text"
                        name="custom_field"
                        defaultValue={customDetails['Custom Field']}
                        onChange={e => 
                        setCustomDetails({
                            ...customDetails,
                            'Custom Field': e.target.value,
                        })
                        }
                    />
                    </div>
                </div>
                </div>
            )*/}

            {/* <p className="subtitle">Log in details</p> */}

            {/* Enable for Klavyio integration 
            <div className={styles.emailPreferences}>
                <p className="subtitle">Email Preferences</p>
                <Dialog open={dialogMessage ? true : false} title="Confirm subscription" size="sm" hideBtnCancel disableBackdropClick onOk={() => clearDialog()}>Please check your email to confirm your subscription to the selected list.</Dialog>
                {!emailLists && (
                <>
                    Fetching preferences...
                </>
                )}

                {emailLists && (
                <ul className={styles.emailLists}>
                    {emailLists.map((list, listIndex) => {
                    const listName = list.list.list_name;
                    const listId = list.list.list_id;

                    return (
                        <li key={listIndex}>
                        <div className={styles.emailCheckbox}>
                            <Loader />
                            <input type="checkbox" id={listId} checked={subscribed[listId] ? 'checked' : ''} onChange={(e) => toggleSubscription(e, listId)} />
                        </div>
                        <label htmlFor={listId} className={styles.emailListName}>{listName}</label>
                        </li>
                    )
                    })}
                </ul>
                )}
            </div> */}
        </div>

        <div className={`${styles.passwordSection} ${reverseDisplayClasses}`}>
            <h6><ContentfulTranslationText keyName="change">Change</ContentfulTranslationText> <ContentfulTranslationText keyName="password">Password</ContentfulTranslationText></h6>
            <div className={styles.passwordInputsContainer}>
                
                <div className={reverseDisplayClasses}>
                    <FormInputField
                        type="password"
                        id='new_password' 
                        labelName={get(translations, 'newPassword.title', 'New Password')}
                        value={accountDetails.new_password} 
                        handleChange={handleChange} 
                        error={fieldErrors.new_password}
                    />
                    <div className={styles.errorContainer}>
                        <span className={styles.error}>
                            {fieldErrors.new_password}
                        </span>
                    </div>
                </div>
                <div className={reverseDisplayClasses}>
                    <FormInputField
                        type="password"
                        id='confirm_password' 
                        labelName={get(translations, 'confirmPassword.title', 'Confirm Password')} 
                        value={accountDetails.confirm_password} 
                        handleChange={handleChange} 
                        error={fieldErrors.confirm_password}
                    />
                    <div className={styles.errorContainer}>
                        <span className={styles.error}>
                            {fieldErrors.confirm_password}
                        </span>
                    </div>
                </div>
                <div className={reverseDisplayClasses}>
                    <FormInputField
                        type="password"
                        id='current_password' 
                        labelName={get(translations, 'currentPassword.title', 'Current Password')}  
                        value={accountDetails.current_password} 
                        handleChange={handleChange} 
                        error={fieldErrors.current_password}
                    />
                    <div className={styles.errorContainer}>
                        <span className={styles.error}>
                            {fieldErrors.current_password}
                        </span>
                    </div>
                </div>
                
                {/* <div className="formField">
                    <label htmlFor="newPassword">New Password</label>
                    <input
                        type="password"
                        id="newPassword"
                        autoComplete="new-password"
                        onChange={e =>
                        setAccountDetails({
                            ...accountDetails,
                            new_password: e.target.value
                        })
                        }
                    />
                    {fieldErrors && 'new_password' in fieldErrors && (
                        <span className="error">{fieldErrors.new_password}</span>
                    )}
                </div> */}
                {/* <div className="formField">
                <label htmlFor="confirmPassword">Confirm Password</label>
                <input
                    type="password"
                    id="confirmPassword"
                    onChange={e =>
                    setAccountDetails({
                        ...accountDetails,
                        confirm_password: e.target.value
                    })
                    }
                />
                {fieldErrors && 'new_password' in fieldErrors && (
                    <span className="error">{fieldErrors.new_password}</span>
                )}
                </div> */}
                {/* <div
                    className={`formField ${
                        accountDetails.new_password === '' ? 'hidden' : 'show'
                    }`}
                    >
                    <label htmlFor="currentPassword">Current Password</label>
                    <input
                        type="password"
                        id="currentPassword"
                        onChange={e =>
                        setAccountDetails({
                            ...accountDetails,
                            current_password: e.target.value
                        })
                        }
                    />
                    {fieldErrors && 'current_password' in fieldErrors && (
                        <span className="error">
                        {fieldErrors.current_password}
                        </span>
                    )}
                </div> */}
            </div>
        </div>

        <div className={styles.btnGroup}>
            <Button
            type="span"
            level="primary"
            onClick={
                !editing ? () => toggleEditing(!editing) : () => saveDetails()
            }
            >
                {!editing ? get(translations, 'updateDetails.title', 'Update details') : (busy ? (get(translations, 'pleaseWait.title', 'Please wait') + '...') : get(translations, 'save.title', 'Save'))}
            </Button>
            <Button
            type="span"
            level="secondary"
            className={!editing ? styles.none : ''}
            onClick={() => toggleEditing(!editing)}
            >
                <ContentfulTranslationText keyName="cancel">Cancel</ContentfulTranslationText>
            </Button>
        </div>
        </div>
    );
}

const AccountOutput = () => {
    const contentful = useContext(ContentfulContext);
    const translations = get(contentful, 'translationData', {});

    return (
        <AccountPageWrapper metaTitle="Account - Settings" title={get(translations, 'settings.title', 'Settings')}>
            <Account transObj={translations} />
        </AccountPageWrapper>
    )
}

export default AccountOutput